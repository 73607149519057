import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDonate, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons'

import Header from '../../components/Header/Header'
import Layout from '../../components/Layout/Layout'

import { formatPhoneNumber } from '../../utilities/formatPhoneNumber'

import '../../styles/policy.css'

const RightsRestoration = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter
  const bailFundLogo = getImage(pageData.bailFundCollective.logo.photo)

  return (
    <Layout title={pageData.heading}>
      <Helmet>
        <script type="text/javascript">{`
          document.querySelector('[data-tool="verify"]').classList.add('hidden')
          document.querySelector('[data-tool="register"]').classList.add('hidden')
          document.querySelector('[data-tool="locate"]').classList.add('hidden')
        `}
        </script>
      </Helmet>
      <Header background={pageData.headerImage.photo} alt={pageData.headerImage.altText}>{pageData.heading}</Header>
      <div className="main">
        <h2>{pageData.felonyVoting.subTitle}</h2>
        <div className="flex-row flex-mobile reverse">
          <div className="equal video-container">
            {data.markdownRemark.frontmatter.felonyVoting.video && ((data.markdownRemark.frontmatter.felonyVoting.video.includes("youtube") || data.markdownRemark.frontmatter.felonyVoting.video.includes("youtu.be"))
              ? <iframe src={data.markdownRemark.frontmatter.felonyVoting.video} frameBorder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowFullScreen title="Video"></iframe>
              : <video src={data.markdownRemark.frontmatter.felonyVoting.video} controls />
            )}
          </div>
          <div className="equal">
            <ReactMarkdown children={pageData.felonyVoting.info} />
            {pageData.felonyVoting.disenfranchisementCrimes.length > 0 ? (
              <ul>
                {pageData.felonyVoting.disenfranchisementCrimes.map(crime => <li key={crime.crime}>{crime.crime}</li>)}
              </ul>
            ) : null}
          </div>
        </div>
        <ReactMarkdown children={pageData.felonyVoting.finalThoughts} />
        <h2>{pageData.bailFundCollective.name}</h2>
        <div className="flex-row flex-mobile">
          <GatsbyImage image={bailFundLogo} alt={pageData.bailFundCollective.logo.altText} className="equal" />
          <div className="bail-fund-info">
            {pageData.bailFundCollective.info ? (
              <ReactMarkdown children={pageData.bailFundCollective.info} />
            ) : null}
            {pageData.bailFundCollective.phone ? (
              <>
                <h3>Call {pageData.bailFundCollective.name}</h3>
                <div className="flex-row">
                  <FontAwesomeIcon icon={faPhone} style={{ marginRight: '0.5rem' }} />
                  <a href={`tel:+1${pageData.bailFundCollective.phone}`}>{formatPhoneNumber(pageData.bailFundCollective.phone.toString())}</a>
                </div>
              </>
            ) : null}
            {pageData.bailFundCollective.email ? (
              <>
                <h3>Email {pageData.bailFundCollective.name}</h3>
                <div className="flex-row">
                  <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '0.5rem' }} />
                  <a href={`mailTo:${pageData.bailFundCollective.email}`}>{pageData.bailFundCollective.email}</a>
                </div>
              </>
            ) : null}
            <h3>Donate</h3>
            <div className="flex-row">
              <FontAwesomeIcon icon={faDonate} style={{ marginRight: '0.5rem' }} />
              <a href={pageData.bailFundCollective.donate.link}>{pageData.bailFundCollective.donate.donateText}</a>
            </div>
          </div>
        </div>
        <h2>{pageData.suffrage.subTitle}</h2>
        <ReactMarkdown children={pageData.suffrage.info} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query RightsRestorationQuery {
  markdownRemark(frontmatter: {title: {eq: "Rights Restoration"}}) {
    frontmatter {
      heading
      headerImage {
        photo {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        altText
      }
      felonyVoting {
        subTitle
        info
        disenfranchisementCrimes {
          crime
        }
        finalThoughts
        video
      }
      bailFundCollective {
        name
        phone
        email
        info
        donate {
          link
          donateText
        }
        logo {
          photo {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
          altText
        }
      }
      suffrage {
        subTitle
        info
      }
    }
  }
}
`

export default RightsRestoration